import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Box } from "@chakra-ui/core"
import Headroom from "react-headroom"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      navLogo: file(absolutePath: { regex: "/prohood-logo.png/" }) {
        childImageSharp {
          fluid(maxWidth: 186) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <Headroom>
        <Box as="nav" bg="gray.100" p={4} d="flex" justifyContent="center">
          <Box w={["160px", "240px"]}>
            <Image
              fluid={data.navLogo.childImageSharp.fluid}
              alt="Prohood logo"
            />
          </Box>
        </Box>
      </Headroom>
      <main>{children}</main>
      <Box
        as="footer"
        bg="blue.900"
        color="gray.400"
        py={4}
        px={2}
        d="flex"
        justifyContent="center"
        textAlign="center"
        fontSize="sm"
      >
        Copyright © 2020 prohood.biz All Right Reserved
      </Box>
    </>
  )
}

export default Layout
